<template>
  <section :id="id">
    <v-bottom-sheet v-model="showSheet" inset>
      <div class="d-flex" style="background-color: white">
        <v-sheet color="primary" class="pa-8 d-flex align-center">
          <div style="text-align: center">
          <img src="../assets/evaluate-white.svg" :width="180" alt="Evaluate"/><br>
          <span style="font-size: 28px; font-weight: 600; color: white">+</span><br>
          <img src="../assets/stratsys.png" style="width: 200px;" alt="Stratsys"/>
          </div>
        </v-sheet>
        <div class="pa-8" style="flex: 1">
          <div style="font-weight: 600; font-size: 24px" class="mb-4" v-if="$i18n.locale === 'sv'">Evaluate blir en del av Stratsys</div>
          <div style="font-weight: 600; font-size: 24px" class="mb-4" v-else>Evaluate becomes part of Stratsys</div>
          <div v-if="$i18n.locale === 'sv'"><a href="https://www.stratsys.com/sv/nyheter/evaluate-leverantorsbedomning" target="_blank">Läs mer hos Stratsys</a></div>
          <div v-else><a href="https://www.stratsys.com/knowledge-hub/evaluate-supplier-assessment" target="_blank">Read more at Stratsys</a></div>
        </div>
        <div class="pa-4" v-if="$i18n.locale === 'sv'"><v-btn color="secondary" text small @click="showSheet = false">Stäng</v-btn></div>
        <div class="pa-4" v-else><v-btn color="secondary" text small @click="showSheet = false">Close</v-btn></div>
      </div>
    </v-bottom-sheet>
    <v-row no-gutters>
      <v-col cols="12">
        <component
            :is="`section-${section}`"
            v-for="section in sections"
            :key="section"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import BaseSubtitle from '@/components/base/Subtitle.vue';

export default {
  name: 'BaseView',
  components: {BaseSubtitle},
  props: {
    id: {
      type: String,
      default: 'view',
    },
  },

  data: () => ({
    showSheet: true,
    sections: []
  }),

};
</script>
